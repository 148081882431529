import { button } from '@/styles/fonts'
import { UseFocusableConfig, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

type ButtonStyleProps = {
  focused: boolean
}

export type FocusableProps = UseFocusableConfig<unknown> & {
  axeRole?: string // role to be read aloud by screen reader
  axeLabel?: string // text to be read aloud by screen reader
  onFocus?: (focused: boolean) => void
  onBlur?: (focused: boolean) => void
}

export type ButtonProps = FocusableProps

const PrimaryButtonStyle = styled.button<ButtonStyleProps>`
  ${button}
  border: none;
  outline: none;
  text-transform: uppercase !important;
  background-color: var(${({ focused }) => (focused ? '--button-focus' : '--button')});
  color: var(${({ focused }) => (focused ? '--on-button-focus' : '--on-button-dark')});
  padding: 32px;
  border-radius: 4px;
  transition: background-color 150ms, color 150ms;
  white-space: nowrap;
  text-align: center;
`
// accessiblity/aria props for buttons
export const PrimaryButton = ({
  children,
  onFocus,
  onBlur,
  axeLabel,
  axeRole,
  ...focusableProps
}: PropsWithChildren<ButtonProps>) => {
  const { ref, focused } = useFocusable(focusableProps)
  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(focused)
    }
  }
  const handleOnFocus = () => {
    if (onFocus) {
      onFocus(focused)
    }
  }
  return (
    <PrimaryButtonStyle
      ref={ref}
      focused={focused}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      aria-label={axeLabel}
      role={axeRole}
    >
      {children}
    </PrimaryButtonStyle>
  )
}

const navItemCss = css<ButtonStyleProps>`
  ${button}
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--on-button-dark);
  height: 90px;
  ${({ focused }) =>
    focused &&
    css`
      text-decoration: underline;
      text-decoration-color: red;
      text-underline-offset: 20px;
    `}
`

const NavItemButton = styled.button<ButtonStyleProps>`
  ${navItemCss}
`
const NavItemDiv = styled.div<ButtonStyleProps>`
  ${navItemCss}
`
export const NavItem = ({
  children,
  onBlur,
  onFocus,
  axeLabel,
  axeRole,
  ...focusableProps
}: PropsWithChildren<ButtonProps>) => {
  const { ref, focused } = useFocusable(focusableProps)

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(focused)
    }
  }
  const handleOnFocus = () => {
    if (onFocus) {
      onFocus(focused)
    }
  }

  return (
    <NavItemButton
      ref={ref}
      focused={focused}
      aria-label={axeLabel}
      role={axeRole}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      {children}
    </NavItemButton>
  )
}
