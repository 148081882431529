import { UiContext } from '@/contexts/UiContext'
import useKeyEvent from '@/hooks/useKeyEvent'
import { PrimaryLabel } from '@/styles/fonts'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

const MenuContainer = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: var(--surface);
  position: fixed;
  color: white;
  padding: 64px;
  overflow-wrap: break-word;
  text-align: left;
  font-size: 24px;
  font-family: var(--default-font);
`
const TitleLabel = styled.div`
  font-size: 48px;
  font-weight: 700;
`
const Helpers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`
const HelperLabel = styled.div`
  font-size: 28px;
  color: var(--on-dark);
  margin: 12px 0;
  width: 100%;
`

const DebugMenu = () => {
  const deviceInfo = getWindowDeviceInfo()
  const { isDebugMenuVisible: isVisible, setIsDebugMenuVisible: setVisible } = useContext(UiContext)
  const [currentKey, setCurrentKey] = useState<KeyboardEvent | undefined>()
  const userService = useContext(UserServiceContext)
  const isProd = !isNotInProd()
  const isXbox = deviceInfo.platform === 'tv_xboxone'
  const isTizen = deviceInfo.platform === 'tv_samsung'
  const router = useRouter()
  // if isVisible && isProd, hide the debug menu
  useEffect(() => {
    if (!isVisible) {
      return
    }
    if (isProd) {
      setVisible(false)
    }
    return () => {}
  }, [isVisible, setVisible, isProd])

  // listen for F1 to toggle the debug menu
  const handleToggle = (_: KeyboardEvent) => {
    if (isProd) {
      return
    }
    setVisible(!isVisible)
  }
  const handleLogOutOrReload = (_: KeyboardEvent) => {
    if (isVisible && !isProd && userService.isLoggedIn) {
      // only logout if...
      // 1. not prod
      // 2. the debug menu is visible
      // 3. the user is logged in
      userService.logOut()
      router.replace('/')
    } else {
      const win: Window = window
      win.location.reload()
    }
  }
  // listen for F1/GamepadY to toggle the debug menu
  useKeyEvent('F1', handleToggle)
  useKeyEvent('GamepadY', handleToggle)

  // listen for F2/GamepadX to log out or reload if not logged in
  useKeyEvent('F2', handleLogOutOrReload)
  useKeyEvent('GamepadX', handleLogOutOrReload)

  useEffect(() => {
    const handleKey = (evt: KeyboardEvent) => {
      evt.preventDefault()
      setCurrentKey(evt)
      return false
    }
    addEventListener('keyup', handleKey, { capture: true })
    return () => {
      removeEventListener('keyup', handleKey)
    }
  }, [])
  if (!isVisible || isProd) {
    return <></>
  }
  return (
    <MenuContainer>
      <TitleLabel>DEBUG MENU</TitleLabel>
      {isTizen ? null : (
        <Helpers>
          <HelperLabel>Press {isXbox ? 'Y' : 'F1'}: Close Debug Menu</HelperLabel>
          <HelperLabel>
            Press {isXbox ? 'X' : 'F2'}: {userService.isLoggedIn ? 'Reload app' : 'Log out current user'}
          </HelperLabel>
        </Helpers>
      )}
      <div>
        <PrimaryLabel fontSize="lg">
          Current Keypress - Code: {currentKey?.code ?? 'unknown'}, Key: {currentKey?.key}, KeyCode:{' '}
          {currentKey?.keyCode ?? -1}
        </PrimaryLabel>
      </div>
      {/* <PrimaryLabel fontSize="lg">Current User</PrimaryLabel> */}
      {/* <pre>{JSON.stringify(userService.currentUser?.profile, null, 4)}</pre> */}
    </MenuContainer>
  )
}

export default DebugMenu
