import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'
import { InputDeviceKey } from 'tizen-common-web'

type TizenKeysRegistration = {
  supportedKeys: InputDeviceKey[]
}
const registerTizenKeysIfApplicable = async (): Promise<TizenKeysRegistration> => {
  const fallback: TizenKeysRegistration = { supportedKeys: [] }
  if (!window) {
    return fallback
  }

  try {
    const tizenCore = await import('tizen-common-web')
    if (!tizenCore.tvinputdevice) {
      return fallback
    }

    const inputDevice = tizenCore.tvinputdevice
    const supportedKeys = inputDevice.getSupportedKeys()
    inputDevice.registerKeyBatch([
      'ColorF0Red',
      'ColorF1Green',
      'MediaPlay',
      'MediaPause',
      'MediaPlayPause',
      'MediaRewind',
      'MediaFastForward',
    ])
    return { supportedKeys } as TizenKeysRegistration
  } catch (ex) {
    return fallback
  }
}

export type TizenKeysType = {
  keyForEvent: (event: KeyboardEvent) => InputDeviceKey
  supportedKeys: InputDeviceKey[]
}

export const TizenKeysContext = createContext<TizenKeysType>({
  supportedKeys: [],
  keyForEvent: () => {
    throw new Error('keyForEvent called before initialization')
  },
})

export type TizenKeysProps = PropsWithChildren<{}>
export const TizenKeysProvider = ({ children }: TizenKeysProps) => {
  const [supportedKeys, setSupportedKeys] = useState<InputDeviceKey[]>([])
  const keyForEvent = useCallback(
    (event: KeyboardEvent): InputDeviceKey => {
      const keyCode = event?.keyCode ?? event?.which ?? -1
      const found: InputDeviceKey | undefined = supportedKeys.find((item) => {
        return item.code === keyCode || item.name === event.code || item.name === event.key
      })
      const next: InputDeviceKey = found ?? {
        name: 'Unidentified',
        code: -1,
      }
      return next
    },
    [supportedKeys]
  )
  useEffect(() => {
    registerTizenKeysIfApplicable()
      .then(({ supportedKeys }) => {
        setSupportedKeys(supportedKeys)
      })
      .catch((_) => {
        // ignore
      })
  }, [])

  return <TizenKeysContext.Provider value={{ supportedKeys, keyForEvent }}>{children}</TizenKeysContext.Provider>
}
